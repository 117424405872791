// =========================================================
// * Argon Design System Pro React - v1.0.0 based on Argon Design System Pro - v1.0.2
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/argon-design-system-pro-react
// * Copyright 2020 Creative Tim (https://www.creative-tim.com)
//
// Coded by www.creative-tim.com
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// Bootstrap Functions
@import "bootstrap/functions";

// Custom Variables
@import "custom/variables";

// Theme Variables
@import "theme/variables-pro";

// Bootstrap Core
@import "bootstrap/bootstrap";

// Theme Core
@import "theme/theme-pro";

// Custom Variables
@import "custom/styles";

// React Differences
@import "react/react-differences";


.title {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}
.navbar-brand img {
    height: auto;
}

.sm-only {
    display: none;
}
@media (max-width: 767.98px) {
    .sm-only {
            display: block;
        }
    .flex-scroll-sm-product {
        display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            scrollbar-width: thin;
        
            .col-sm-12 {
                flex: none;
                width: 320px;
            }
    }
    .flex-scroll-sm {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        scrollbar-width: thin;
        .col-sm-12 {
            flex: none;
            width: 280px;
        }
    }
}

.flex-scroll-sm::-webkit-scrollbar {
    height: 6px;
}

.flex-scroll-sm::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
}